.sidemenu {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;

  @media (min-width: $notebook) {
    display: none;
  }
}

.sidemenu__close {
  position: absolute;
  z-index: 101;
  top: 0%;
  right: 0%;
  margin: 0;
  margin-top: px2rem(25px);
  margin-right: px2rem(15px);
  padding: 0;
  background-color: transparent;
  border: 0;

  &:hover,
  &:focus {
    background: none;
  }

  .sidemenu__close-burger {
    position: relative;
    width: px2rem(40px);
    height: px2rem(30px);
  }

  .sidemenu__close-slice {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 20%;
    background-color: black;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: top left;

    &:first-child {
      width: 100%;
      transform: rotate(45deg) scale(1) translateX(-50%) translateY(-50%);
    }

    &:last-child {
      width: 100%;
      transform: rotate(-45deg) scale(1) translateX(-50%) translateY(-50%);
    }
  }
}

.sidemenu__nav {
  overflow: hidden;
  position: fixed;
  padding-top: px2rem(95px);
  top: 0;
  right: -100%;
  width: 85%;
  height: 100%;
  background-color: $white;
  transition: right .4s $ease-in-circ;

  @media (min-width: $tablet) {
    width: 65%;
  }
  
  @media (min-width: $notebook) {
    width: 35%;
  }

  &.sidemenu__nav--opened {
    right: 0%;
    transition: right .8s $ease-out-circ;

    .sidemenu__menu {

      li {
        &:nth-child(1) { .sidemenu__menu-link { transition-delay: 0.0325s * 1; } }
        &:nth-child(2) { .sidemenu__menu-link { transition-delay: 0.0325s * 2; } }
        &:nth-child(3) { .sidemenu__menu-link { transition-delay: 0.0325s * 3; } }
        &:nth-child(4) { .sidemenu__menu-link { transition-delay: 0.0325s * 4; } }
        &:nth-child(5) { .sidemenu__menu-link { transition-delay: 0.0325s * 5; } }
      }

      .sidemenu__menu-link {
        opacity: 1;
        position: relative;
        left: 0%;
        transition-property: all;
        transition-duration: .6s;
        transition-timing-function: $ease-out-circ;
      }
    }
  }

  .sidemenu__menu {
    li {
      margin-bottom: px2rem(20px);
    }
  }

  .sidemenu__menu-link {
    display: inline-block;
    opacity: 0;
    position: relative;
    left: 80%;
    margin-left: px2rem(30px);
    margin-right: px2rem(30px);
    padding-top: px2rem(10px);
    padding-bottom: px2rem(10px);
    color: black;
    font-family: $primaryFont;
    font-size: px2rem(20px);
    font-weight: normal;
    line-height: 1;
    white-space: nowrap;
    transition: all 1s $ease-out-expo 0s;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0%;
      margin-top: px2rem(-8px);
      width: 0%;
      height: px2rem(3px);
      background-color: black;
    }

    &.--is-selected {
      &::after {
        width: 100%;
      }
    }
  }
}