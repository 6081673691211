.customer-service {
  background-color: $blueText;
}

.customer-service__title {
  padding: px2rem(30px) 0;
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(28px);
  font-style: normal;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

.customer-service__form {
  margin-bottom: px2rem(20px);

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .form-group {
    margin-bottom: px2rem(20px);
  }

  label {
    margin-bottom: px2rem(10px);
    color: white;
    font-family: $primaryFont;
    font-size: px2rem(16px);
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }

  .form-control {
    padding: px2rem(25px) px2rem(10px);
    color: white;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: normal;
    font-size: px2rem(16px);
    line-height: 1;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0;

    &::placeholder {
      opacity: .5;
      color: white;
    }

    &.--is-name {
      text-transform: capitalize;

      &::placeholder {
        text-transform: initial;
      }
    }

    &.--is-email {
      text-transform: lowercase;
    }
  }

  small {
    display: none;
  }

  button {
    position: relative;
    margin-top: px2rem(5px);
    padding: px2rem(20px) px2rem(52px);
    color: #345377;
    font-family: $primaryFont;
    font-size: px2rem(20px);
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: uppercase;
    background-color: white;
    border: 0;
    border-radius: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0%;
      height: 100%;
      background-color: $gold;
      transition: width .4s $ease-in-out-circ;
    }

    &.--is-loading {
      &::before {
        left: 0;
        width: 100%;
      }

      .button__loader {
        display: block;
      }

      .button__text {
        opacity: 0;
      }
    }

    .button__loader {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: px2rem(50px);
      height: px2rem(50px);
      transform: translateX(-50%) translateY(-50%);
    }

    .button__text {
      display: block;
      position: relative;
      transition: color .3s $ease-in-out-cubic;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          left: 0;
          width: 100%;
          transition: width .25s $ease-in-out-circ;
        }
    
        .button__text {
          color: white;
          transition: color .3s $ease-in-out-cubic;
        }
      }
    }
  }
}

.customer-service__message {
  h4 {
    margin-bottom: px2rem(10px);
    color: white;
    font-family: $primaryFont;
    font-size: px2rem(22px);
    font-style: normal;
    font-weight: 800;
    line-height: px2rem(30px);
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: white;
    font-family: $primaryFont;
    font-size: px2rem(22px);
    font-style: normal;
    font-weight: normal;
    line-height: px2rem(30px);
    text-align: center;
  }
}

.customer-service__message-icon {
  margin: 0 auto;
  margin-bottom: px2rem(30px);
  width: px2rem(176px);
  height: px2rem(136px);
}