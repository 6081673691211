.header {
  z-index: 1;
  position: relative;
  padding: px2rem(48px) 0;

  &.--is-white {
    .header__button-slice {
      background-color: white;
    }

    .header__menu-link {
      color: white;

      &::after {
        background-color: white;
      }

      @media (hover: hover) {
        &:hover {
          color: white;
        }
      }
    }

    .header__menu-text {
      color: white;
    }
  }
}

.header__logo {
  width: px2rem(95px);
  height: px2rem(61px);
}

.header__button {
  display: block;
  z-index: 1;
  position: relative;
  top: 50%;
  margin: 0;
  padding: 0;
  float: right;
  background-color: transparent;
  border: 0;
  transform: translateY(-50%);

  @media (min-width: $notebook) {
    display: none;
  }

  &:hover,
  &:focus {
    background: none;
  }

  .header__button-burger {
    position: relative;
    width: px2rem(36px);
    height: px2rem(26px);
  }

  .header__button-slice {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 20%;
    background-color: black;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: top left;

    &:first-child {
      transform: rotate(0deg) scale(1) translateX(-50%) translateY(-250%);
    }

    &:last-child {
      transform: rotate(0deg) scale(1) translateX(-50%) translateY(150%);
    }
  }
}

.header__menu {
  display: none;
  height: px2rem(61px);
  border-bottom: 1px solid rgba($color: #c4c4c4, $alpha: 0.1);

  @media (min-width: $notebook) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    float: right;
  }
}

.header__menu-link {
  position: relative;
  margin: 0 px2rem(30px);
  padding-bottom: px2rem(15px);
  color: black;
  font-family: $primaryFont;
  font-size: px2rem(14px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  white-space: nowrap;
  &.building {
    cursor: pointer;
  }
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    height: px2rem(3px);
    background-color: black;
    transition: width .4s $ease-in-out-circ;
  }

  @media (hover: hover) {
    &:hover {
      color: black;

      &::after {
        left: 0;
        width: 100%;
        transition: width .2s $ease-in-out-circ;
      }
    }
  }

  .header__menu-ul {
    text-align: center;
    position: absolute;
    top: 30px;
    left: -20px;
    background: rgba(48, 77, 113, 0.8);
    backdrop-filter: blur(24px);
    z-index: 9999;
    li {
      border-bottom: 1px solid #40597B;
      a {
        display: block;
        padding: 30px;
        color: white;
      }
    }
  }

  &.--is-selected {
    &::after {
      left: 0;
      width: 100%;
    }
  }
}

.header__menu-text {
  display: flex;
  align-items: center;
  margin-left: px2rem(30px);
  padding-bottom: px2rem(8px);
  color: black;
  font-family: $primaryFont;
  font-size: px2rem(14px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  white-space: nowrap;
}

.header__menu-icon {
  display: inline-block;
  margin-right: px2rem(14px);
  width: px2rem(28px);
  height: px2rem(28px);

  svg {
    width: 100%;
    height: 100%;
  }
}