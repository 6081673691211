.building {
  position: relative;
}

.building__background {
  
  &.--one {
    position: absolute;
    left: 50%;
    margin-top: px2rem(60px);
    margin-left: px2rem(-93px);
    width: 150vw;
    height: px2rem(270px);
    background-color: #d69c07;
    transform-origin: left bottom;
    transform: skewY(-27deg) translateX(-50%);

    @media (min-width: $notebook) {
      margin-top: 0;
      height: px2rem(565px);
    }
  }

  &.--two {
    position: absolute;
    left: 50%;
    margin-top: px2rem(160px);
    width: 150vw;
    height: px2rem(370px);
    background-color: $blueText;
    transform-origin: left bottom;
    transform: skewY(27deg) translateX(-50%);

    @media (min-width: $notebook) {
      margin-top: px2rem(330px);
      height: px2rem(1260px);
    }
  }
}

.building__perks {
  text-align: justify;
  z-index: 1;
  position: relative;
  margin-top: px2rem(45px);
  margin-bottom: px2rem(80px);
  max-width: px2rem(560px);

  @media (min-width: $notebook) {
    margin-bottom: px2rem(30px);
  }

}

.building__panoramic {
  display: block;
  margin-bottom: px2rem(45px);
  max-width: px2rem(1115px);

  @media (min-width: $notebook) {
    margin-bottom: px2rem(45px);
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.building__subtitle {
  position: relative;
  margin-bottom: px2rem(40px);
  max-width: px2rem(800px);
  min-height: auto !important;
}

.building__texts {
  text-align: justify;
  position: relative;
  margin-bottom: px2rem(30px);
  max-width: px2rem(800px);
  &.text-right-mobile {
    float: right;
    @media (max-width: $tablet) {
      float: unset;
    }
  }
  .color-white-mobile {
    @media (min-width: $tablet) {
      color: #fff !important;
    }
  }
}

.building__services {
  display: block;
  position: relative;
  margin-bottom: px2rem(80px);
  max-width: px2rem(1115px);

  @media (min-width: $notebook) {
    margin-bottom: px2rem(30px);
  }
}

.building__services-desktop {
  display: none;

  @media (min-width: $notebook) {
    display: block;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.building__services-mobile {
  display: block;

  @media (min-width: $notebook) {
    display: none;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.building__park {
  display: block;
  position: relative;
  margin-bottom: px2rem(100px);
  max-width: px2rem(1115px);
}

.building__park-desktop {
  display: none;

  @media (min-width: $notebook) {
    display: block;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.building__park-mobile {
  display: block;

  @media (min-width: $notebook) {
    display: none;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}