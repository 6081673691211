.breadcrumbs {
  display: none;
  padding: px2rem(18px) 0;

  @media (min-width: $notebook) {
    display: block;
  }

  &.--is-blue {
    .breadcrumbs__link {
      color: $blueText;

      &::after {
        background-color: $blueText;
      }

      @media (hover: hover) {
        &:hover {
          color: $blueText;
        }
      }
    }
  }
}

.breadcrumbs__menu {
  display: flex;
  flex-direction: row;
}

.breadcrumbs__link {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: px2rem(15px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(12px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;

  &::before {
    content: '>';
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    padding: 0 px2rem(3px);
    font-weight: normal;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: px2rem(-2px);
    width: 0%;
    height: 1px;
    background-color: white;
    transition: width .4s $ease-in-out-circ;
  }

  &:first-child {
    margin-left: 0;

    &::before {
      display: none;
    }
  }

  &:last-child {
    font-weight: 900;

    &::after {
      display: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: white;

      &::after {
        left: 0;
        width: 100%;
        transition: width .2s $ease-in-out-circ;
      }
    }
  }
}