.work {
  position: relative;
  padding-bottom: px2rem(20px);
}

.work__pie {
  width: px2rem(160px);
  height: px2rem(160px);

  @media (min-width: $notebook) {
    width: px2rem(165px);
    height: px2rem(165px);
  }
}

.work__percentage {
  color: $greyText !important;
  font-family: $primaryFont !important;
  // font-size: px2rem(40px) !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.work__label {
  display: block;
  padding: px2rem(24px) px2rem(14px);
  max-width: px2rem(165px);
  color: black;
  font-family: $secondaryFont;
  font-size: px2rem(20px);
  font-style: normal;
  font-weight: normal;
  line-height: px2rem(23px);
  text-align: center;
}

.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

.pie {
  position: absolute;
  border: $circle-width solid $circle-primary-color;
  width: 1 - (2 * $circle-width);
  height: 1 - (2 * $circle-width);
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  @include rotate(0deg);
}

.pie-fill {
  @include rotate(180deg);
}

.c100 {
  *,
  *:before,
  *:after {
    @include box-sizing(content-box);
  }
  position: relative;
  font-size: px2rem(160px);
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  // margin: 0 0.1em 0.1em 0;
  background-color: $circle-secondary-color;

  @media (min-width: $notebook) {
    font-size: px2rem(165px);
  }

  // center circle to its parent
  &.center {
    float: none;
    margin: 0 auto;
  }
  // bigger size
  &.big {
    font-size: 240px;
  }
  // smaller size
  &.small {
    font-size: 80px;
  }
  // centered value inside circle
  >span {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    width: 5em;
    line-height: 5em;
    font-size: 0.2em;
    color: $circle-secondary-color;
    display: block;
    text-align: center;
    white-space: nowrap;
    @include transition-property(all);
    @include transition-duration(0.2s);
    @include transition-timing-function(ease-out);
  }
  // background inside the circle
  &:after {
    position: absolute;
    top: $circle-width;
    left: $circle-width;
    display: block;
    content: " ";
    border-radius: 50%;
    background-color: $circle-bg-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    @include transition-property(all);
    @include transition-duration(0.2s);
    @include transition-timing-function(ease-in);
  }
  // the slice (mask)
  .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
  }
  // circle to show the status
  .bar {
    @extend .pie;
  }
  // loop to create all needed elements automatically
  @for $i from 51 through 100 {
    &.p#{$i} {
      & .slice {
        @extend .rect-auto;
      }
      &.bar:after {
        @extend .pie-fill;
      }
      & .fill {
        @extend .pie;
        @extend .pie-fill;
      }
    }
  }
  // loop to rotate all 100 circles
  @for $i from 1 through 100 {
    &.p#{$i} .bar {
      $degs: (360/100 * $i);
      @include rotate(#{$degs}deg);
    }
  }
  // hover styles
  &:hover {
    cursor: default;
    >span {
      width: 3.33em;
      line-height: 3.33em;
      font-size: 0.3em;
      color: $circle-primary-color;
    }
    &:after {
      top: $circle-width-hover;
      left: $circle-width-hover;
      width: 1 - (2 * $circle-width-hover);
      height: 1 - (2 * $circle-width-hover);
    }
  }
}