.contacts {
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0;
}

.contacts__container {
  position: relative;
}

.contacts__button {
  position: absolute;
  bottom: px2rem(25px);
  right: px2rem(25px);
  width: px2rem(70px);
  height: px2rem(70px);
  border-radius: 50%;
  box-shadow: 0 0 px2rem(20px) 0 rgba($color: black, $alpha: 0.35);

  @media (min-width: $notebook) {
    width: px2rem(100px);
    height: px2rem(100px);
  }
}