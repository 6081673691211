@import 'vendor/bootstrap-4.3.1/bootstrap';
//@import 'vendor/bootstrap-4.3.1/bootstrap-grid';
//@import 'vendor/bootstrap-4.3.1/bootstrap-reboot';
// @import 'vendor/slick-1.8.1/slick';
// @import 'vendor/slick-1.8.1/slick-theme';
// @import 'vendor/fancybox/jquery.fancybox';

@import 'easings';
@import 'fonts';
@import 'mixins';
@import 'vars';

@import 'base';

@import 'layout/header';
@import 'layout/footer';
@import 'layout/sales';
@import 'layout/sidemenu';

@import 'pages/home';
@import 'pages/about';
@import 'pages/building';
@import 'pages/region';
@import 'pages/map';
@import 'pages/construction';

@import 'components/breadcrumbs';
@import 'components/contacts';
@import 'components/overlay';
@import 'components/paragraph';
@import 'components/title';
@import 'components/work';
@import 'components/customer-service';