.about {
  position: relative;
}

.about__background {
  
  &.--one {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: px2rem(1100px);
    background: linear-gradient(0deg, #00002A 0%, #345377 150%);

    @media (min-width: $notebook) {
      background: linear-gradient(0deg, #00002A -50.24%, #345377 100%);
    }
  }

  &.--two {
    overflow: hidden;
    position: absolute;
    top: px2rem(300px);
    left: 50%;
    margin-left: -1px;
    width: 100vw;
    height: px2rem(1200px);
    transform: translateX(-50%);

    @media (min-width: $notebook) {
      top: px2rem(-90px);
    }

    picture {
      display: block;
      position: relative;
      left: 50%;
      width: px2rem(1440px);
      height: px2rem(1784px);
      transform: translateX(-50%);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: px2rem(570px);
        left: 50%;
        width: 100%;
        height: px2rem(100px);
        background-color: white;
        transform-origin: left bottom;
        transform: skewY(-31deg) translateX(-50%);
  
        @media (min-width: $notebook) {
          display: none;
        }
      }
    }
    
    img {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &.--three {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: px2rem(500px);
    left: 50%;
    margin-left: -1px;
    width: 100%;
    transform: translateX(-50%);

    @media (min-width: $notebook) {
      top: px2rem(150px);
      width: px2rem(1440px);
    }

    .building-desktop {
      display: none;

      @media (min-width: $notebook) {
        display: block;
        width: px2rem(860px);
      }
    }

    .building-mobile {
      display: block;
      width: px2rem(829px);

      @media (min-width: $notebook) {
        display: none;
      }
    }
  }

  &.--four {
    position: absolute;
    left: 50%;
    margin-top: px2rem(540px);
    margin-left: -1px;
    width: 100vw;
    height: px2rem(1850px);
    background-color: $blueText;
    transform-origin: left bottom;
    transform: skewY(26deg) translateX(-50%);

    @media (min-width: $notebook) {
      left: 50%;
      height: px2rem(850px);
      margin-top: px2rem(70px);
    }
  }

  &.--five {
    position: absolute;
    top: px2rem(0px);
    left: 50%;
    transform: translateX(-50%);

    picture, img {
      width: 100%;
      height: 100%;
      @media (max-width: $tablet) {
        width: 166%;
        height: 117%;
      }
    }

    .pool-desktop {
      display: none;
      margin-top: px2rem(450px);
      width: px2rem(1440px);
      height: px2rem(1841px);

      @media (min-width: $notebook) {
        display: block;
      }
    }

    .pool-mobile {
      display: block;
      margin-top: px2rem(600px);
      width: px2rem(375px);
      height: px2rem(1000px);

      @media (min-width: $notebook) {
        display: none;
      }
      &.second {
        margin-top: px2rem(300px);
      }
    }
  }

  &.--six {
    position: absolute;
    left: 50%;
    margin-top: px2rem(650px);
    margin-left: -1px;
    width: 100vw;
    height: px2rem(4030px);
    background-color: $blueText;
    transform-origin: left bottom;
    transform: skewY(26deg) translateX(-50%);

    @media (min-width: $notebook) {
      height: px2rem(1000px);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      margin-top: -1px;
      width: 100%;
      height: px2rem(75px);
      background-color: #345377;

      @media (min-width: $notebook) {
        height: px2rem(150px);
      }
    }
  }
}

.about__tag {
  display: inline-block;
  padding: px2rem(20px);
  width: 100%;
  color: white;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: normal;
  font-size: px2rem(18px);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  border: 1px solid white;

  @media (min-width: $notebook) {
    width: auto;
    font-size: px2rem(20px);
  }
}

.about__enterprise {
  z-index: 0;
  position: relative;
  padding-top: px2rem(10px);
  padding-bottom: 0;

  @media (min-width: $notebook) {
    padding-bottom: 0;
  }
}

.about__enterprise-status {

  @media (min-width: $notebook) {
    padding-top: px2rem(25px);
    padding-bottom: px2rem(25px);
  }
}

.about__enterprise-info {
  padding: px2rem(40px) 0;
}

.about__enterprise-info-location {
  display: block;
  margin-bottom: px2rem(30px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(56px);
  font-style: normal;
  font-weight: 800;
  line-height: 1.15;

  @media (min-width: $notebook) {
    margin-bottom: px2rem(15px);
    font-size: px2rem(56px);
    line-height: 1;
  }
}

.about__enterprise-info-metrics {
  display: block;
  margin-bottom: px2rem(40px);
  color: #d69c07;
  font-family: $primaryFont;
  font-size: px2rem(60px);
  font-style: normal;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: $notebook) {
    margin-bottom: px2rem(10px);
  }

  span {
    display: none;

    @media (min-width: $notebook) {
      display: inline;
    }
  }
}

.about__text {
  font-family: $primaryFont;
  font-size: px2rem(24px);
  color: white;
  display: block;
  line-height: 181.69%;
  font-weight: 100;
  font-style: normal;
  .link {
    color: white;
    font-weight: bold;
  }
}

.about__enterprise-question {
  display: block;
  margin-bottom: px2rem(30px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(56px);
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;

  @media (min-width: $notebook) {
    margin-bottom: px2rem(15px);
    font-size: px2rem(40px);
    line-height: 1;
  }
}

.about__enterprise-more {

  .about__tag {
    margin-bottom: px2rem(16px);

    @media (min-width: $notebook) {
      margin-right: px2rem(26px);
    }
  }
}

.about__section2-mobile {
  display: block;

  @media (min-width: $notebook) {
    display: none;
  }
}

.about__section2-title {
  display: block;
  margin-top: px2rem(20px);
  margin-bottom: px2rem(55px);
  color: white;
  font-family: $primaryFont;
  line-height: px2rem(41px);
  font-style: normal;
  font-weight: 100;
  font-size: px2rem(30px);
  text-align: center;
  @media (min-width: $notebook) {
    margin-top: 0;
    margin-bottom: px2rem(32px);
    text-align: left;
    border-left: 1px solid;
    padding-left: 30px;
  }
}

.about__section2-message {
  h4 {
    margin-bottom: px2rem(10px);
    color: white;
    font-family: $primaryFont;
    font-size: px2rem(22px);
    font-style: normal;
    font-weight: 800;
    line-height: px2rem(30px);
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $notebook) {
      text-align: left;
    }
  }

  p {
    color: white;
    font-family: $primaryFont;
    font-size: px2rem(22px);
    font-style: normal;
    font-weight: normal;
    line-height: px2rem(30px);
    text-align: center;

    @media (min-width: $notebook) {
      max-width: px2rem(320px);
      text-align: left;
    }
  }
}

.about__section2-message-icon {
  margin: 0 auto;
  margin-bottom: px2rem(30px);
  width: px2rem(176px);
  height: px2rem(136px);
  
  @media (min-width: $notebook) {
    margin: initial;
    margin-bottom: px2rem(30px);
  }
}

.about__section2-form {
  max-width: px2rem(385px);
  min-height: px2rem(440px);

  .form-group {
    margin-bottom: px2rem(24px);
  }

  label {
    margin-bottom: px2rem(18px);
    color: white;
    font-family: $primaryFont;
    font-size: px2rem(20px);
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }

  .form-control {
    padding: px2rem(30px);
    color: white;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: normal;
    font-size: px2rem(20px);
    line-height: 1;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0;

    &::placeholder {
      opacity: .5;
      color: white;
    }

    &.--is-name {
      text-transform: capitalize;

      &::placeholder {
        text-transform: initial;
      }
    }

    &.--is-email {
      text-transform: lowercase;
    }
  }

  small {
    display: none;
  }

  button {
    position: relative;
    margin-top: px2rem(5px);
    padding: px2rem(20px) px2rem(52px);
    color: #345377;
    font-family: $primaryFont;
    font-size: px2rem(20px);
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: uppercase;
    background-color: white;
    border: 0;
    border-radius: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0%;
      height: 100%;
      background-color: $gold;
      transition: width .4s $ease-in-out-circ;
    }

    &.--is-loading {
      &::before {
        left: 0;
        width: 100%;
      }

      .button__loader {
        display: block;
      }

      .button__text {
        opacity: 0;
      }
    }

    .button__loader {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: px2rem(50px);
      height: px2rem(50px);
      transform: translateX(-50%) translateY(-50%);
    }

    .button__text {
      display: block;
      position: relative;
      transition: color .3s $ease-in-out-cubic;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          left: 0;
          width: 100%;
          transition: width .25s $ease-in-out-circ;
        }
    
        .button__text {
          color: white;
          transition: color .3s $ease-in-out-cubic;
        }
      }
    }
  }
}

.about__building {
  padding-top: px2rem(80px);
  height: 100%;

  @media (min-width: $notebook) {
    padding-top: px2rem(210px);
  }

  &::before {
    content: '';
    display: block;
    margin-bottom: px2rem(20px);
    margin-left: auto;
    margin-right: auto;
    width: 1px;
    height: px2rem(161px);
    background-color: white;
  }
}

.about__building-info {
  margin: 0 auto;
  width: 100%;
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(30px);
  font-style: normal;
  font-weight: normal;
  line-height: px2rem(41px);
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $notebook) {
    width: px2rem(540px);
    text-align: left;
  }

  strong {
    font-weight: 800;
  }
}

.about__hall {
  position: relative;
}

.about__hall-desktop {
  display: none;

  @media (min-width: $notebook) {
    display: block;
  }
}

.about__hall-mobile {
  display: block;

  @media (min-width: $notebook) {
    display: none;
  }
}

.about__hall-text {
  position: relative;
  margin-top: px2rem(125px);
  width: px2rem(300px);
  color: #bb8a0b;
  font-family: $primaryFont;
  font-size: px2rem(30px);
  font-style: normal;
  font-weight: normal;
  line-height: px2rem(41px);
  text-transform: uppercase;

  @media (min-width: $notebook) {
    margin-top: px2rem(250px);
    width: px2rem(260px);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0%;
    margin-bottom: px2rem(20px);
    margin-left: px2rem(35px);
    width: 1px;
    height: px2rem(72px);
    background-color: #2B456A;

    @media (min-width: $notebook) {
      top: 50%;
      bottom: auto;
      margin-bottom: 0;
      left: 100%;
      margin-left: px2rem(25px);
      width: px2rem(72px);
      height: 1px;
    }
  }

  strong {
    font-weight: 800;
  }
}

.about__hall-photo {
  padding-top: px2rem(30px);
  max-width: px2rem(800px);

  img {
    width: 100%;
    height: auto;
  }
}

.about__advantages {
  position: relative;
  padding-top: px2rem(200px);
  padding-bottom: px2rem(105px);

  @media (min-width: $notebook) {
    padding-top: px2rem(245px);
  }
  img {
    min-height: 287px;
    object-fit: cover;
  }
}

.about__advantages-surprise {
  position: relative;
  border-left: 2px solid #2b456a;
  max-width: px2rem(729px);
  color: #2b456a;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: normal;
  line-height: px2rem(41px);
  font-size: 21px;
  @media (min-width: $notebook) {
    font-size: px2rem(30px);
  }

  strong {
    font-weight: 800;
  }

  &.dots {
    &::after {
      content: "•";
      font-size: 45px;
      color: #2b456a;
      position: absolute;
      left: -14px;
      top: -20px;
    }
  }
}

.about__advantages-text {
  position: relative;
  color: #2b456a;
  font-family: $primaryFont;
  max-width: px2rem(1008px);
  font-size: px2rem(24px);
  font-style: normal;
  font-weight: bold;
  line-height: px2rem(33px);

}

.about__advantages-text2 {
  position: relative;
  border-left: 2px solid #2b456a;
  position: relative;
  color: #2b456a;
  font-family: $primaryFont;
  max-width: px2rem(1100px);
  font-size: px2rem(24px);
  font-style: normal;
  font-weight: bold;
  line-height: px2rem(33px);
  &.dots {
    &::after {
      content: "•";
      font-size: 45px;
      color: #2b456a;
      position: absolute;
      left: -14px;
      top: -20px;
    }
  }
}



.about__advantages-leisure {
  position: relative;
  width: 100%;
  color: $blue;
  font-family: $primaryFont;
  font-size: px2rem(30px);
  font-style: normal;
  font-weight: 800;
  line-height: px2rem(41px);
  text-transform: uppercase;

  @media (min-width: $notebook) {
    margin-left: px2rem(105px);
    width: px2rem(400px);
    color: white;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    margin-right: px2rem(33px);
    width: px2rem(72px);
    height: 1px;
    background-color: white;
  }
}

.about__galery {
  position: relative;
}

.about__galery-title {
  display: block;
  position: relative;
  margin: 0 auto;
  color: $blueText;
  font-family: $primaryFont;
  font-size: px2rem(30px);
  font-style: normal;
  font-weight: 800;
  line-height: px2rem(41px);
  text-align: center;
  text-transform: uppercase;

  &::after {
    content: '';
    display: block;
    margin-top: px2rem(50px);
    margin-left: auto;
    margin-right: auto;
    width: 1px;
    height: px2rem(60px);
    background-color: $blueText;
  }

  .bold {
    font-weight: 800;
  }
}

.about__gallery-menu {
  display: none;
  position: relative;
  margin-top: px2rem(38px);
  margin-bottom: px2rem(38px);

  @media (min-width: $notebook) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.about__gallery-menu-mobile {
  display: block;
  margin-top: px2rem(38px);
  margin-bottom: px2rem(38px);

  @media (min-width: $notebook) {
    display: none;
  }

  .swiper-slide {
    text-align: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: black;
    outline: none;
  }
}

.about__gallery-menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 px2rem(15px);
  width: px2rem(210px);
  height: px2rem(65px);
  color: $darkBlue;
  font-family: $primaryFont;
  font-size: px2rem(20px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid $darkBlue;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    background-color: $darkBlue;
    transition: width .4s $ease-in-out-circ;
  }

  span {
    position: relative;
    transition: color .3s $ease-in-out-cubic;
  }

  &.--is-selected {
    &::before {
      left: 0;
      width: 100%;
      transition: width .25s $ease-in-out-circ;
    }

    span {
      color: white;
      transition: color .3s $ease-in-out-cubic;
    }
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        left: 0;
        width: 100%;
        transition: width .25s $ease-in-out-circ;
      }
  
      span {
        color: white;
        transition: color .3s $ease-in-out-cubic;
      }
    }
  }
}

.about__gallery-photos {
  user-select: none;
  display: none;
  position: relative;
  margin-bottom: px2rem(60px);
  height: px2rem(500px);
  @media (max-width: $tablet) {
    max-height: 250px !important;
  }
  img {
    @media (max-width: $tablet) {
      max-height: 250px !important;
      object-fit: cover;
    }
  }
  @media (min-width: $notebook) {
    margin-bottom: px2rem(110px);
  }

  &.--is-visible {
    display: block;
  }

  .swiper-container {
    max-width: px2rem(1000px);
  }

  .swiper-button-prev {
    color: white;
    outline: none;
    left: 0;
    @media (min-width: $notebook) {
      color: black;
    }
  }

  .swiper-button-next {
    right: 0;
    color: white;
    outline: none;

    @media (min-width: $notebook) {
      color: $darkBlue;
    }
  }
}

.about__gallery-photos-legend {
  z-index: 10;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: px2rem(70px);
  background-color: rgba($color: black, $alpha: 0.5);
}

.about__gallery-photos-legend-numbers {
  display: inline-flex;
  align-items: center;
  padding: 0 px2rem(20px);
  height: 100%;
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(20px);
  font-style: normal;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: rgba($color: black, $alpha: 0.3);

  @media (min-width: $notebook) {
    padding: 0 px2rem(60px);
  }
}

.about__gallery-photos-legend-label {
  margin-left: px2rem(20px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(20px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  white-space: nowrap;

  @media (min-width: $notebook) {
    margin-left: px2rem(40px);
  }
}



.about__footer {
  margin-top: px2rem(300px);
  height: px2rem(680px);
  @media (min-width: $notebook) {
    margin-top: px2rem(500px);
  }
  &--title {
    position: relative;
    color: #fff;
    font-family: $primaryFont;
    font-size: px2rem(40px);
    max-width: px2rem(488px);
    font-style: normal;
    font-weight: bold;
    line-height: px2rem(60px);
    @media (min-width: $notebook) {
      font-size: px2rem(56px);
      line-height: px2rem(76px);
    }
  }
  &--subtitle {
    font-family: $primaryFont;
    max-width: px2rem(488px);
    font-style: normal;
    font-weight: bold;
    font-size: px2rem(24px);
    line-height: px2rem(33px);
    color: #FFFFFF;
  }
  .link {
    color: white;
    font-weight: bold;
  }
}

.padding-mobile {
  padding-top: 600px;
  position: relative;
  text-align: center;
  display: block;
  @media (min-width: $notebook) {
    padding-top: 0;
  }
}
