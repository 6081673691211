.footer {
  z-index: 1;
  position: relative;
  padding-top: px2rem(80px);
}

.footer__caption {
  margin-bottom: px2rem(35px);
  color: black;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: normal;
  font-size: px2rem(18px);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  @media (min-width: $notebook) {
    text-align: left;
  }
}

.footer__realestate {
  padding-bottom: px2rem(26px);
}

.footer__realestate-logo {
  display: flex;
  justify-content: center;
  height: px2rem(150px);

  @media (min-width: $notebook) {
    justify-content: left;
    height: px2rem(113px);
  }

  svg {
    height: 100%;
  }
}

.footer__sponsors {
  padding: px2rem(60px) 0;
}

.footer__sponsors-logo {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (min-width: $notebook) {
    justify-content: flex-start;
    height: px2rem(88px);
  }

  &.--has-margin {
    margin-bottom: px2rem(120px);

    @media (min-width: $notebook) {
      margin-bottom: 0;
    }
  }

  .cgs {
    max-width: px2rem(279px);
  }

  .wise-capital {
    max-width: px2rem(236px);

    @media (min-width: $notebook) {
      max-width: px2rem(176px);
    }
  }
}

.footer__disclaimer {
  display: block;
}

.footer__disclaimer-text {
  padding: px2rem(70px) 0;
  color: $greyText;
  font-family: $primaryFont;
  font-style: normal;
  font-weight: normal;
  font-size: px2rem(14px);
  line-height: px2rem(19px);
  border-top: 1px solid black;
}