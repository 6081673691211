.title {
  z-index: 0;
  position: relative;
  min-height: px2rem(168px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(30px);
  font-style: normal;
  font-weight: normal;
  line-height: px2rem(41px);
  text-transform: uppercase;

  @media (min-width: $notebook) {
    min-height: px2rem(123px);
  }

  strong {
    font-weight: 900;
  }

  &.--is-blue {
    color: $blueText;
  }

  &.--is-gold {
    color: $goldText;
  }

  &.--is-headline {
    margin-top: px2rem(25px);
    
    @media (min-width: $notebook) {
      margin-top: px2rem(62px);
      padding-right: px2rem(62px);
      max-width: px2rem(695px);
    }
  }

  &.--with-border {
    border-bottom: 1px solid $blueText;
  }
}

.subtitle {
  z-index: 10;
  position: relative;
  min-height: 60px;
  color: white;
  font-family: $primaryFont;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  line-height: px2rem(41px);
  text-transform: uppercase;

  @media (min-width: $notebook) {
    min-height: 60px;
  }

  strong {
    font-weight: 900;
  }

  &.--is-blue {
    color: $blueText;
  }

  &.--is-gold {
    color: $goldText;
  }

  &.--is-headline {
    margin-top: px2rem(25px);
    
    @media (min-width: $notebook) {
      margin-top: px2rem(62px);
      padding-right: px2rem(62px);
      max-width: px2rem(695px);
    }
  }

  &.--with-border {
    border-bottom: 1px solid $blueText;
  }
}