.region {
  position: relative;
}

.region__background {
  position: absolute;
  left: 50%;
  margin-top: px2rem(60px);
  margin-left: px2rem(-63px);
  width: 150vw;
  height: px2rem(785px);
  background-color: $blueText;
  transform-origin: left bottom;
  transform: skewY(-27deg) translateX(-50%);

  @media (min-width: $notebook) {
    margin-top: 0;
    height: px2rem(1472px);
  }
  &.congonhas {
    height: px2rem(1500px);
    @media (min-width: $notebook) {
      height: px2rem(1700px);
    }
  }
  &.vila-mascote {
    height: px2rem(1400px);
  }
  &.mapa-do-site {
    margin-top: px2rem(350px);
    height: px2rem(344px);
    background-color: #E5E5E5;
    opacity: 0.4;
  }
  &.parques {
    height: px2rem(1100px);
    @media (min-width: $notebook) {
      height: px2rem(1400px);
    }
  }
}

.region__subtitle {
  position: relative;
  margin-bottom: px2rem(40px);
  max-width: px2rem(800px);
  min-height: auto !important;
  text-align: left;
}

.region__neighborhoods {
  text-align: justify;
  position: relative;
  margin-top: px2rem(45px);
  margin-bottom: px2rem(95px);
  max-width: px2rem(595px);
}

.region__panoramic {
  display: block;
  margin-bottom: px2rem(60px);
  max-width: px2rem(1115px);
}

.region__panoramic-desktop {
  display: none;

  @media (min-width: $desktop) {
    display: block;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.region__panoramic-mobile {
  display: block;

  @media (min-width: $desktop) {
    display: none;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.region__infra {
  text-align: justify;
  position: relative;
  margin-bottom: px2rem(80px);
  max-width: px2rem(780px);
}

.region__metro {
  z-index: 1;
  display: block;
  position: relative;
  margin-bottom: px2rem(65px);
  max-width: px2rem(1115px);
}

.region__metro-desktop {
  display: none;

  @media (min-width: $desktop) {
    display: block;
  }

  picture, img {
    width: 100%;
    height: auto;
  }
}

.region__metro-mobile {
  display: block;

  @media (min-width: $desktop) {
    display: none;
  }

  picture, img {
    width: 100%;
    height: auto;
  }
}

.region__mobility {
  text-align: justify;
  z-index: 1;
  position: relative;
  margin-bottom: px2rem(70px);
}

.region__family {
  display: block;
  margin-bottom: px2rem(60px);
  max-width: px2rem(1115px);
}

.region__family-desktop {
  display: none;

  @media (min-width: $desktop) {
    display: block;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.region__family-mobile {
  display: block;

  @media (min-width: $desktop) {
    display: none;
  }

  picture, img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.region__surroundings {
  text-align: justify;
  z-index: 1;
  position: relative;
  margin-bottom: px2rem(70px);
}

.region__services {
  display: block;
  position: relative;
  margin-bottom: px2rem(65px);
  max-width: px2rem(1115px);
}

.region__services-desktop {
  display: none;

  @media (min-width: $desktop) {
    display: block;
  }

  picture, img {
    width: 100%;
    height: auto;
  }
}

.region__services-mobile {
  display: block;

  @media (min-width: $desktop) {
    display: none;
  }

  picture, img {
    width: 100%;
    height: auto;
  }
}
