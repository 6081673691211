.map {

  hr {
    border: 1px solid #2B456A;
    opacity: 0.4;
  }

  &__title {
    color: #2B456A;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: px2rem(30px);
    line-height: 1;
    text-transform: uppercase;
  }

  &__subtitle {
    color: #000;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: px2rem(22px);
    line-height: 30px;
    a {
      color: #000;
    }
  }

}

