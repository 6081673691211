.sales {
  z-index: 1000;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #345377, $alpha: 0.95);

  &.--is-visible {
    display: block;
  }
}

.sales__container {
  padding-top: px2rem(30px);

  @media (min-width: $notebook) {
    padding-top: px2rem(100px);
  }
}

.sales__close {
  display: block;
  margin-bottom: px2rem(60px);
}

.sales__title {
  margin-bottom: px2rem(40px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(30px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
}

.sales__text {
  margin-bottom: px2rem(55px);
  max-width: px2rem(600px);
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(22px);
  font-style: normal;
  font-weight: 800;
  line-height: px2rem(30px);
}

.sales__buttons {
  display: flex;
  flex-flow: wrap row;
}

.sales__button {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: px2rem(16px);
  padding: 0 px2rem(20px);
  width: 100%;
  height: px2rem(66px);
  border: 1px solid white;

  @media (min-width: $notebook) {
    margin-bottom: 0;
    margin-left: px2rem(32px);
    width: px2rem(176px);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    background-color: white;
    transition: width .4s $ease-in-out-circ;
  }

  &:first-child {
    margin-left: 0;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        left: 0;
        width: 100%;
        transition: width .25s $ease-in-out-circ;
      }

      &:nth-child(1) { .sales__button-icon { path { stroke: $blue; } } }
      &:nth-child(2) { .sales__button-icon { path { stroke: $blue; } } }
      &:nth-child(3) { .sales__button-icon { path { fill: $blue; } } }
      &:nth-child(4) { .sales__button-icon { path { stroke: $blue; } } }
  
      .sales__button-text {
        color: $blue;
        transition: color .3s $ease-in-out-cubic;
      }
    }
  }
}

.sales__button-icon {
  position: relative;
  margin-right: px2rem(12px);
}

.sales__button-text {
  display: block;
  position: relative;
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(20px);
  font-style: normal;
  font-weight: 800;
  line-height: px2rem(27px);
  transition: color .3s $ease-in-out-cubic;
}