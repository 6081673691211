$min-font: 16px;

$tablet: 768px;
$notebook: 1024px;
$desktop: 1200px;
$celphone: 450px;

$primaryFont: $lato;
$secondaryFont: $roboto;

$blue: #345377;
$darkBlue: #00002a;
$gold: #bb8a0b;

$blueText: #2b456a;
$goldText: $gold;
$greyText: #5b5b5b;

$circle-width: 0.1em;
$circle-width-hover: $circle-width / 2;
$circle-primary-color: $gold;
$circle-secondary-color: #e5e7e9;
$circle-bg-color: #f6f6f6;