*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p, span {
  margin: 0;
  padding: 0;
  line-height: 1;
}

a,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

ul, li {
  margin: 0;
  padding: 0;
  line-height: 1;
  list-style: none;
}

img {
  display: block;
  outline: none;
  max-width: inherit !important;
  max-height: inherit !important;
}

input,
input:focus,
select,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

button,
button:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent;
}

blockquote {
  p {
    text-align: center;
    padding-top: 10px;
    position: relative;
    &.--white {
      color: #fff;
    }
  }
}