.overlay {
  z-index: 100;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: black, $alpha: 0.75);

  &.--is-visible {
    display: block;
  }
}