.construction {
  position: relative;
}

.construction__background {
  position: absolute;
  left: 50%;
  margin-top: px2rem(190px);
  margin-left: px2rem(43px);
  width: 150vw;
  height: px2rem(1650px);
  background-color: #f6f6f6;
  transform-origin: left bottom;
  transform: skewY(-27deg) translateX(-50%);

  @media (min-width: $notebook) {
    margin-top: 0;
    height: px2rem(1060px);
  }
}

.construction__progress {
  position: relative;
  margin-top: px2rem(48px);
  margin-bottom: px2rem(220px);
  
  @media (min-width: $notebook) {
    margin-top: px2rem(105px);
    margin-bottom: 0;
  }
}

.construction__progress-total {
  display: inline-block;
  position: relative;
  margin-bottom: px2rem(105px);
  padding: px2rem(22px);
  width: 100%;
  color: white;
  font-family: $primaryFont;
  font-size: px2rem(25px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: $blueText;

  @media (min-width: $notebook) {
    padding: px2rem(32px);
    width: auto;
    font-size: px2rem(30px);
  }

  strong {
    font-weight: 900;
  }

  span {
    display: block;
    margin-top: px2rem(20px);
    padding: px2rem(10px) 0;
    font-size: px2rem(12px);
    background-color: rgba($color: #000000, $alpha: .25);
  }
}

.construction__evolution {
  position: relative;
  margin-top: px2rem(48px);
  margin-bottom: px2rem(30px);
}

.construction__evolution-date {
  display: inline-block;
  margin-bottom: px2rem(98px);
  padding: px2rem(12px);
  color: white;
  font-family: $secondaryFont;
  font-size: px2rem(16px);
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  white-space: nowrap;
  background-color: $blueText;

  @media (min-width: $notebook) {
    margin-bottom: px2rem(26px);
  }
}

.construction__evolution-photo {
  display: block;
  padding: px2rem(26px) 0;

  picture, img {
    width: 100%;
    height: 255px;
    object-fit: cover;
  }
}