.paragraph {
  margin-bottom: px2rem(25px);
  color: white;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: normal;
  font-size: px2rem(16px);
  line-height: px2rem(21px);

  &:last-child {
    margin-bottom: 0;
  }

  &.--is-blue {
    color: #22375c;
  }

  &.--is-grey {
    color: $greyText;
  }

  .link-white {
    color: #fff;
    font-weight: bold;
  }
  .link-grey {
    color: #5b5b5b;
    font-weight: bold;
  }
}